import React, { useRef } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import SortableItem from './SortableItem';

const SortableList = (props) => {
  const itemRef = useRef(null);
  return (
    <table className='sticky-table'>
      <thead>
        <tr>
          <th>
            <div className='table-checkbox'>&nbsp;</div>
          </th>
          {props.items.map((value, index) => (
            value.is_fixed === 1 &&
            <th key={`item-${index}`}>
              <div className='table-contentbox'>{value.field_name}</div>
            </th>
          ))}
          {props.items.map((value, index) => (
            value.is_fixed === 0 &&
            <SortableItem ref={itemRef} key={`item-${index}`} index={value.ordering} value={value} />

          ))}
          <th>
            <div className='table-addfieldbox'>&nbsp;</div>
          </th>
        </tr>
      </thead>
    </table>
  );
};

export default SortableContainer(SortableList);
