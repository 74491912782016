import React, { Component } from "react";

export default class AddLabel extends Component {

    state = {
        color: '#00ff94',
        label: 'Add ' + this.props.defaultname
    }

    changeColorVal(e) {
        this.setState({ color: e.target.value });
    }

    changeLabelVal(e) {
        this.setState({ label: e.target.value });
    }

    render() {
        return (
            <div className="col-12 mb-2">
                <h5 className="p-2 text-start">Add new {this.props.defaultname}</h5>
                <div className="row m-2 p-2" style={{ border: '1px solid #cccccc6b' }}>
                    <div className="col-1"><input type="color" defaultValue={this.state.color} onChange={(e) => this.changeColorVal(e)} /></div>
                    <div className="col-9">
                        <input type="text" className="form-control" defaultValue={this.state.label} onKeyUp={(e) => this.changeLabelVal(e)} />
                    </div>
                    <div className="col-2 text-end">
                        <span className="btn btn-sm btn-primary" onClick={() => this.props.addLabelFunc(this.props.board_id, this.props.field_id, this.state.color, this.state.label)}>
                            <i className="fa fa-plus"></i> Add {this.props.defaultname}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}