import React, { Component } from "react";


export default class FileList extends Component {

    render() {
        return (
            <div className="row">
                {this.props.value !== '' && JSON.parse(this.props.value).map((img, index) =>
                    <div className="col-4 mb-2" key={index}>
                        <div className="file-content">
                            <div className="content-holder p-2 text-center">
                                <img className="rounded mx-auto d-block" src="/file.png" />

                            </div>
                            <div className="file-overlay">
                                <span className="me-2 btn btn-sm btn-outline-info" onClick={() => this.props.getFileFunc(img)} title="Download"><i className="fas fa-arrow-alt-circle-down"></i>
                                </span>
                                <span className="btn btn-sm btn-outline-danger" onClick={() => this.props.deleteProjectFileFunc(this.props.board_id, this.props.project_id, this.props.field_id, img)} title="Delete">
                                    <i className="fas fa-times-circle"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

}