import './App.css';
import Main from './monday/Main.js';

function App() {
  return (
    <Main></Main>
  );
}

export default App;
