import React, { Component } from "react";
import SortableList from "./SortableList";
import { arrayMoveImmutable } from 'array-move';
import DatePicker from "react-datepicker";
import validator from "validator";

import "react-datepicker/dist/react-datepicker.css";
import TableCell from "./TableCell";
import Search from "./Search";
import AddLabel from "./AddLabel";
import FileList from "./FileList";

class Main extends Component {

  constructor(props) {
    super(props)

    this.searchProjects = this.searchProjects.bind(this)
    this.addNewLabel = this.addNewLabel.bind(this)
    this.deleteProjectFile = this.deleteProjectFile.bind(this)
    this.getFile = this.getFile.bind(this)
  }

  state = {
    board: [],
    board_id: 1,
    types: [],
    projects: [],
    items: [],
    editFieldHtml: '',
    boards: [],
    showLoader: false
  }

  apiUrl = 'https://crm.mortgagetools.ca/laravel/public';

  async componentDidMount() {
    try {
      const response = await fetch(this.apiUrl + '/api/boards/' + this.state.board_id);
      const board = await response.json();
      this.setState({ board: board.board });
      this.setState({ board_id: board.board.id });
      this.setState({ projects: board.projects });
      this.setState({ types: board.types });
      this.setState({ boards: board.boards });
      const result = Object.values(board.fieldOrders);
      this.setState({ items: result });
    } catch (err) {
      console.log(err)
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({ showLoader: true })
    const board = this.state.board;
    let board_id = this.state.board_id;
    let prevItem = this.state.items;
    prevItem = arrayMoveImmutable(prevItem, oldIndex, newIndex);
    fetch(this.apiUrl + '/api/update/order', {
      method: 'POST',
      body: JSON.stringify({
        board_id: board_id,
        orders: prevItem
      }),
      headers: {
        'Cache-Control': 'no-cache',
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => response.json())
      .then(response => {
        this.setState({ board: response.board });
        this.setState({ board_id: response.board.id });
        this.setState({ projects: response.projects });
        this.setState({ types: response.types });
        this.setState({ boards: response.boards });
        this.setState({ showLoader: false });
        const result = Object.values(board.fieldOrders);
        this.setState({ items: result });
      }).catch((err) => {
        console.log(err.message);
      });;

  };


  async addNewHeader(board_id, type, name) {
    this.setState({ showLoader: true })
    try {
      const response = await fetch(this.apiUrl + '/api/header', {
        method: 'POST',
        body: JSON.stringify({
          board_id: board_id,
          type: type,
          name: name
        }),
        headers: {
          'Cache-Control': 'no-cache',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      const board = await response.json();
      this.setState({ board: board.board });
      this.setState({ board_id: board.board.id });
      this.setState({ projects: board.projects });
      this.setState({ types: board.types });
      this.setState({ boards: board.boards });
      this.setState({ showLoader: false })
    } catch (err) {
      console.log(err);
    }
  }

  async updateFieldName(e, board_id, field_id) {
    this.setState({ showLoader: true })
    try {
      const response = await fetch(this.apiUrl + '/api/board/field', {
        method: 'POST',
        body: JSON.stringify({
          board_id: board_id,
          field_id: field_id,
          name: e.target.value
        }),
        headers: {
          'Cache-Control': 'no-cache',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      const data = await response.json();
      this.setState({ board: data.board });
      this.setState({ board_id: data.board.id });
      this.setState({ projects: data.projects });
      this.setState({ types: data.types });
      this.setState({ boards: data.boards });
      this.setState({ showLoader: false })
    } catch (err) {
      console.log(err);
    }
  }

  async deleteBoardField(board_id, field_id) {
    var r = window.confirm("Are your sure?")
    if (r === true) {
      this.setState({ showLoader: true })
      try {
        const response = await fetch(this.apiUrl + '/api/delete/field', {
          method: 'POST',
          body: JSON.stringify({
            board_id: board_id,
            field_id: field_id
          }),
          headers: {
            'Cache-Control': 'no-cache',
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
        const data = await response.json();
        this.setState({ board: data.board });
        this.setState({ board_id: data.board.id });
        this.setState({ projects: data.projects });
        this.setState({ types: data.types });
        this.setState({ boards: data.boards });
        this.setState({ showLoader: false })
      } catch (err) {
        console.log(err);
      }
    }
  }

  async addProject(e, board_id, group_id) {
    if (e.target.value != '') {
      this.setState({ showLoader: true })

      try {
        const response = await fetch(this.apiUrl + '/api/project', {
          method: 'POST',
          body: JSON.stringify({
            board_id: board_id,
            group_id: group_id,
            name: e.target.value
          }),
          headers: {
            'Cache-Control': 'no-cache',
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
        const data = await response.json();
        this.setState({ board: data.board });
        this.setState({ board_id: data.board.id });
        this.setState({ projects: data.projects });
        this.setState({ types: data.types });
        this.setState({ boards: data.boards });
        this.setState({ showLoader: false })
        e.target.value = '';
      } catch (err) {
        console.log(err);
      }
    }

  }

  getFieldValue(project_id, field_id) {
    const projectList = this.state.projects;
    let res = '';
    projectList.filter((el) => {
      if (el.project_id == project_id && el.field_id == field_id) {
        res = el.field_value
      }
    });
    return res;
  }

  getFieldHtml(board_id, project_id, field_id) {
    let projectList = this.state.projects;
    let fieldList = this.state.board.fields;
    let res = '';
    let value = '';
    let action = '';
    let type = {};
    projectList.filter((el) => {
      if (el.project_id == project_id && el.field_id == field_id) {
        value = el.field_value
      }
    });
    fieldList.filter((field) => {
      if (field.id == field_id) {
        action = field.action_type
        type = field.json != '' ? JSON.parse(field.json) : {}
        type = Object.values(type)
      }
    });

    switch (action) {
      case 'date':
        let selectedDate = (value != '') ? new Date(value) : '';
        res = <div className={'w-100 h-100 field-type-' + action}>
          <DatePicker showIcon className="form-control" todayButton="Today" dateFormat="yyyy-MM-dd" selected={selectedDate} onChange={(date) => this.changeFieldDate(board_id, project_id, field_id, date)} />
        </div>;
        break;

      case 'timeline':
        let selectedDates = (value != '') ? JSON.parse(value) : '';
        res = <div className={'w-100 h-100 field-type-' + action}>
          <DatePicker className="form-control" dateFormat="yyyy-MM-dd"
            selected={(selectedDates !== null && selectedDates[0] !== null && selectedDates[0] !== undefined) ? new Date(selectedDates[0]) : null}
            onChange={(dates) => this.changeFieldDate(board_id, project_id, field_id, dates)}
            startDate={(selectedDates !== null && selectedDates[0] !== null && selectedDates[0] !== undefined) ? new Date(selectedDates[0]) : null}
            endDate={(selectedDates !== null && selectedDates[1] !== null && selectedDates[1] !== undefined) ? new Date(selectedDates[1]) : null}
            selectsRange={true}
          />
        </div>;
        break;

      case 'label':
      case 'priority':
      case 'status':
        let name = '&nbsp;';
        let color = '#cccccc';
        type.filter((el) => {
          if (value !== null && el.id == value) {
            name = el.name
            color = el.color
          }
        });
        res = <div className={'w-100 h-100 field-type-' + action}>
          <div className="no-toggle-icon dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div className="status_print_color" style={{ backgroundColor: color }}>
              <div className="add-status-note"></div>
              <span className="status_txt" dangerouslySetInnerHTML={{ __html: name }}></span>
            </div>
          </div>
          <div className="dropdown-menu dropdown-menu-start table-popup-box">
            <div className="row statusPicker_list">
              {type.map((data, i) =>
                <div className="col-6" key={i}>
                  <span className={data.id == value ? 'p-2 d-block mb-2 text-center text-white custom-type-picker selected' : 'p-2 d-block mb-2 text-center text-white custom-type-picker'} style={{ backgroundColor: data.color }} onClick={() => this.updatePickerValue(board_id, project_id, field_id, data)} dangerouslySetInnerHTML={{ __html: data.name }}></span>
                </div>
              )}
              <div className="col-12">
                <span className="btn d-block statusPicker_footerButton" data-bs-toggle="modal" data-bs-target={'#labelModal_' + action}>
                  <svg viewBox="0 0 20 20" fill="currentColor" width="20" height="20" aria-hidden="true"><path d="M13.8542 3.59561C13.8541 3.59568 13.8542 3.59555 13.8542 3.59561L4.80915 12.6503L3.81363 16.189L7.35682 15.1957L16.4018 6.14C16.4746 6.06722 16.5161 5.96795 16.5161 5.86503C16.5161 5.76221 16.4753 5.6636 16.4026 5.59083C16.4025 5.59076 16.4026 5.59091 16.4026 5.59083L14.4038 3.59568C14.3309 3.52292 14.232 3.48197 14.1289 3.48197C14.026 3.48197 13.927 3.52297 13.8542 3.59561ZM12.8051 2.54754C13.1562 2.19695 13.6324 2 14.1289 2C14.6254 2 15.1016 2.19693 15.4527 2.54747C15.4527 2.5475 15.4527 2.54745 15.4527 2.54747L17.4515 4.54263C17.8026 4.89333 18 5.36914 18 5.86503C18 6.36091 17.8028 6.8365 17.4518 7.18719L8.26993 16.3799C8.17984 16.4701 8.06798 16.5356 7.94516 16.57L2.94244 17.9724C2.68418 18.0448 2.4069 17.9723 2.21725 17.7829C2.0276 17.5934 1.95512 17.3165 2.02768 17.0586L3.43296 12.0633C3.46728 11.9413 3.53237 11.8301 3.62199 11.7404L12.8051 2.54754Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
                  Edit Labels
                </span>
              </div>
            </div>
          </div>
        </div>;
        break;


      case 'number':
        res = <div className={'w-100 h-100 field-type-' + action}>
          <input type="number" min={0} step="1" className="form-control" placeholder="" defaultValue={value} onBlur={(e) => this.changeFieldType(board_id, project_id, field_id, e, action)} />
        </div>;
        break;

      case 'link':
        res = <div className={'w-100 h-100 field-type-' + action}>
          <input type="text" className="form-control" defaultValue={value} onBlur={(e) => this.changeFieldType(board_id, project_id, field_id, e, action)} />
        </div>;
        break;

      case 'files':
        res = <div className={'w-100 h-100 field-type-' + action}>
          {value !== '' && JSON.parse(value).length > 0 &&
            <div className="file-data d-inline-block col-9 text-start">
              <span className="d-block" data-bs-toggle="modal" data-bs-target={'#fileModal-' + project_id + field_id}> {JSON.parse(value).length} file(s)</span>
              <div className="modal fade" id={'fileModal-' + project_id + field_id} tabIndex="-1" aria-labelledby={'labellModal' + project_id + field_id} aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id={'labellModal' + project_id + field_id}>Files</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <FileList board_id={this.state.board_id} project_id={project_id} field_id={field_id} value={value} getFileFunc={this.getFile} deleteProjectFileFunc={this.deleteProjectFile} />
                        <label className="d-block">
                          <span className="btn d-block statusPicker_footerButton"><i className="fa fa-plus"></i> Add File</span>
                          <input type="file" className="form-control d-none" onChange={(e) => this.uploadFiles(board_id, project_id, field_id, e)} />
                        </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          <label className="d-inline-block">
            <span className="badge bg-secondary rounded-pill"><i className="fa fa-plus"></i></span>
            <input type="file" className="form-control d-none" onChange={(e) => this.uploadFiles(board_id, project_id, field_id, e)} />

          </label>
        </div>;
        break;

      default:
        res = <div className={'w-100 h-100 field-type-' + action}>
          <input type="text" className="form-control" placeholder="" defaultValue={value} onBlur={(e) => this.changeFieldType(board_id, project_id, field_id, e, action)} />
        </div>;
        break;
    }
    return res
  }

  async uploadFiles(board_id, project_id, field_id, e) {
    this.setState({ showLoader: true })

    try {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append('board_id', board_id);
      formData.append('project_id', project_id);
      formData.append('field_id', field_id);
      const response = await fetch(this.apiUrl + '/api/upload/files', {
        method: 'POST',
        body: formData,
      })
      const data = await response.json();
      this.setState({ board: data.board });
      this.setState({ board_id: data.board.id });
      this.setState({ projects: data.projects });
      this.setState({ types: data.types });
      this.setState({ boards: data.boards });
      this.setState({ showLoader: false });
    } catch (err) {
      console.log(err);
    }
  }

  async deleteProjectFile(board_id, project_id, field_id, img) {
    var r = window.confirm('Are you sure?');
    if (r === true) {
      this.setState({ showLoader: true })
      try {
        const response = await fetch(this.apiUrl + '/api/remove/file', {
          method: 'POST',
          body: JSON.stringify({
            board_id: board_id,
            project_id: project_id,
            field_id: field_id,
            img: img
          }),
          headers: {
            'Cache-Control': 'no-cache',
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
        const data = await response.json();
        this.setState({ board: data.board });
        this.setState({ board_id: data.board.id });
        this.setState({ projects: data.projects });
        this.setState({ types: data.types });
        this.setState({ boards: data.boards });
        this.setState({ showLoader: false });
      } catch (err) {
        console.log(err);
      }
    }

  }

  async getFile(img) {
    try {
      const response = await fetch(this.apiUrl + '/api/get/file', {
        method: 'POST',
        body: JSON.stringify({
          file: img
        }),
        headers: {
          'Cache-Control': 'no-cache',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      const data = await response.json();
      const link = document.createElement('a');
      link.href = this.apiUrl + data.url;
      link.target = '_blank'
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (err) {
      console.log(err);
    }
  }

  async updatePickerValue(board_id, project_id, field_id, value) {
    this.setState({ showLoader: true })
    try {
      const response = await fetch(this.apiUrl + '/api/projectmeta', {
        method: 'POST',
        body: JSON.stringify({
          board_id: board_id,
          project_id: project_id,
          field_id: field_id,
          value: value.id
        }),
        headers: {
          'Cache-Control': 'no-cache',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      const data = await response.json();
      this.setState({ board: data.board });
      this.setState({ board_id: data.board.id });
      this.setState({ projects: data.projects });
      this.setState({ types: data.types });
      this.setState({ boards: data.boards });
      this.setState({ showLoader: false })
    } catch (err) {
      console.log(err);
    }
  }

  async changeFieldDate(board_id, project_id, field_id, date) {
    this.setState({ showLoader: true })
    try {
      const response = await fetch(this.apiUrl + '/api/projectmeta', {
        method: 'POST',
        body: JSON.stringify({
          board_id: board_id,
          project_id: project_id,
          field_id: field_id,
          value: date
        }),
        headers: {
          'Cache-Control': 'no-cache',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      const data = await response.json();
      this.setState({ board: data.board });
      this.setState({ board_id: data.board.id });
      this.setState({ projects: data.projects });
      this.setState({ types: data.types });
      this.setState({ boards: data.boards });
      this.setState({ showLoader: false })
    } catch (err) {
      console.log(err);
    }

  }

  async changeFieldType(board_id, project_id, field_id, e, type) {
    this.setState({ showLoader: true })
    if (type == 'link' && !validator.isURL(e.target.value) && e.target.value != '') {
      alert('Not a valid link');
      e.target.value = '';
    } else {
      try {
        const response = await fetch(this.apiUrl + '/api/projectmeta', {
          method: 'POST',
          body: JSON.stringify({
            board_id: board_id,
            project_id: project_id,
            field_id: field_id,
            value: e.target.value
          }),
          headers: {
            'Cache-Control': 'no-cache',
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
        const data = await response.json();
        this.setState({ board: data.board });
        this.setState({ board_id: data.board.id });
        this.setState({ projects: data.projects });
        this.setState({ types: data.types });
        this.setState({ boards: data.boards });
        this.setState({ showLoader: false })
      } catch (err) {
        console.log(err);
      }
    }
  }

  async changeBoardName(e, board_id) {
    this.setState({ showLoader: true })
    try {
      const response = await fetch(this.apiUrl + '/api/board/name', {
        method: 'POST',
        body: JSON.stringify({
          board_id: board_id,
          name: e.target.value
        }),
        headers: {
          'Cache-Control': 'no-cache',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      const data = await response.json();
      this.setState({ board: data.board });
      this.setState({ board_id: data.board.id });
      this.setState({ projects: data.projects });
      this.setState({ types: data.types });
      this.setState({ boards: data.boards });
      this.setState({ showLoader: false })
    } catch (err) {
      console.log(err);
    }
  }

  async updateGroupColor(e, board_id, group_id) {
    this.setState({ showLoader: true })
    try {
      const response = await fetch(this.apiUrl + '/api/group/color', {
        method: 'POST',
        body: JSON.stringify({
          board_id: board_id,
          group_id: group_id,
          color: e.target.value
        }),
        headers: {
          'Cache-Control': 'no-cache',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      const data = await response.json();
      this.setState({ board: data.board });
      this.setState({ board_id: data.board.id });
      this.setState({ projects: data.projects });
      this.setState({ types: data.types });
      this.setState({ boards: data.boards });
      this.setState({ showLoader: false })
    } catch (err) {
      console.log(err);
    }
  }

  async updateGroupName(e, board_id, group_id) {
    this.setState({ showLoader: true })
    try {
      const response = await fetch(this.apiUrl + '/api/group/name', {
        method: 'POST',
        body: JSON.stringify({
          board_id: board_id,
          group_id: group_id,
          name: e.target.value
        }),
        headers: {
          'Cache-Control': 'no-cache',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      const data = await response.json();
      this.setState({ board: data.board });
      this.setState({ board_id: data.board.id });
      this.setState({ projects: data.projects });
      this.setState({ types: data.types });
      this.setState({ boards: data.boards });
      this.setState({ showLoader: false })
    } catch (err) {
      console.log(err);
    }
  }

  async addNewGroup(board_id) {
    this.setState({ showLoader: true })
    //add/group
    try {
      const response = await fetch(this.apiUrl + '/api/add/group', {
        method: 'POST',
        body: JSON.stringify({
          board_id: board_id,
        }),
        headers: {
          'Cache-Control': 'no-cache',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      const data = await response.json();
      this.setState({ board: data.board });
      this.setState({ board_id: data.board.id });
      this.setState({ projects: data.projects });
      this.setState({ types: data.types });
      this.setState({ boards: data.boards });
      this.setState({ showLoader: false })
    } catch (err) {
      console.log(err);
    }
  }

  async searchProjects(e) {
    const board_id = this.state.board_id;
    this.setState({ showLoader: true })
    //add/group
    try {
      const response = await fetch(this.apiUrl + '/api/search/project', {
        method: 'POST',
        body: JSON.stringify({
          board_id: board_id,
          search: e.target.value
        }),
        headers: {
          'Cache-Control': 'no-cache',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      const data = await response.json();
      this.setState({ board: data.board });
      this.setState({ board_id: data.board.id });
      this.setState({ projects: data.projects });
      this.setState({ types: data.types });
      this.setState({ boards: data.boards });
      this.setState({ showLoader: false });
    } catch (err) {
      console.log(err);
    }
  }

  async changeActiveBoard(board_id) {
    this.setState({ showLoader: true })
    try {
      const response = await fetch(this.apiUrl + '/api/boards/' + board_id);
      const board = await response.json();
      this.setState({ board: board.board });
      this.setState({ board_id: board.board.id });
      this.setState({ projects: board.projects });
      this.setState({ types: board.types });
      this.setState({ boards: board.boards });
      const result = Object.values(board.fieldOrders);
      this.setState({ items: result });
      this.setState({ showLoader: false })
    } catch (err) {
      console.log(err)
    }
  }

  async addNewBoard() {
    this.setState({ showLoader: true })
    try {
      const response = await fetch(this.apiUrl + '/api/add/board', {
        method: 'POST',
        headers: {
          'Cache-Control': 'no-cache',
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      const board = await response.json();
      this.setState({ board: board.board });
      this.setState({ board_id: board.board.id });
      this.setState({ projects: board.projects });
      this.setState({ types: board.types });
      this.setState({ boards: board.boards });
      const result = Object.values(board.fieldOrders);
      this.setState({ items: result });
      this.setState({ showLoader: false })
    } catch (err) {
      console.log(err)
    }
  }

  modelHtml(board_id, header) {
    let modelNeeded = ['label', 'priority', 'status'];
    return (
      <>
        {modelNeeded.indexOf(header.action_type) > -1 &&
          <div className="modal fade" id={'labelModal_' + header.action_type} tabIndex="-1" aria-labelledby={'labellModal' + header.action_type} aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id={'labellModal' + header.action_type}>Edit {header.action_type}</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    {Object.values(JSON.parse(header.json)).map((option, index) =>
                      <div className="col-4 mb-2" key={index}>
                        <div className="row m-2 p-2" style={{ border: '1px solid #cccccc6b' }}>
                          <div className="col-2 p-0">
                            <input type="color" className="ds-color-picker" defaultValue={option.color} onBlur={(e) => this.changeColorLabel(board_id, header.id, option.id, e)} />
                          </div>
                          <div className="col-8">
                            <input type="text" className="form-control" defaultValue={option.name} onBlur={(e) => this.changeNameLabel(board_id, header.id, option.id, e)} />
                          </div>
                          <div className="col-2">
                            <span className="text-danger" onClick={() => this.removeLabel(board_id, header.id, option.id)}><i className="fa fa-times"></i></span>
                          </div>
                        </div>
                      </div>
                    )}
                    <AddLabel board_id={board_id} field_id={header.id} defaultname={header.action_type} addLabelFunc={this.addNewLabel} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    )
  }

  async addNewLabel(board_id, field_id, color, label) {
    this.setState({ showLoader: true })
    try {
      const response = await fetch(this.apiUrl + '/api/add/label', {
        method: 'POST',
        body: JSON.stringify({
          board_id: board_id,
          field_id: field_id,
          color: color,
          label: label,
        }),
        headers: {
          'Cache-Control': 'no-cache',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      const data = await response.json();
      this.setState({ board: data.board });
      this.setState({ board_id: data.board.id });
      this.setState({ projects: data.projects });
      this.setState({ types: data.types });
      this.setState({ boards: data.boards });
      this.setState({ showLoader: false });
    } catch (err) {
      console.log(err);
    }
  }

  async removeLabel(board_id, field_id, labelId) {
    var r = window.confirm("Are your sure?")
    if (r === true) {
      this.setState({ showLoader: true })
      try {
        const response = await fetch(this.apiUrl + '/api/remove/label', {
          method: 'POST',
          body: JSON.stringify({
            board_id: board_id,
            field_id: field_id,
            labelId: labelId,
          }),
          headers: {
            'Cache-Control': 'no-cache',
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
        const data = await response.json();
        this.setState({ board: data.board });
        this.setState({ board_id: data.board.id });
        this.setState({ projects: data.projects });
        this.setState({ types: data.types });
        this.setState({ boards: data.boards });
        this.setState({ showLoader: false });
      } catch (err) {
        console.log(err);
      }
    }
  }

  async changeColorLabel(board_id, field_id, labelId, e) {
    this.setState({ showLoader: true })
    try {
      const response = await fetch(this.apiUrl + '/api/change/label/color', {
        method: 'POST',
        body: JSON.stringify({
          board_id: board_id,
          field_id: field_id,
          labelId: labelId,
          color: e.target.value,
        }),
        headers: {
          'Cache-Control': 'no-cache',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      const data = await response.json();
      this.setState({ board: data.board });
      this.setState({ board_id: data.board.id });
      this.setState({ projects: data.projects });
      this.setState({ types: data.types });
      this.setState({ boards: data.boards });
      this.setState({ showLoader: false });
    } catch (err) {
      console.log(err);
    }
  }

  async changeNameLabel(board_id, field_id, labelId, e) {
    this.setState({ showLoader: true })
    try {
      const response = await fetch(this.apiUrl + '/api/change/label/name', {
        method: 'POST',
        body: JSON.stringify({
          board_id: board_id,
          field_id: field_id,
          labelId: labelId,
          name: e.target.value,
        }),
        headers: {
          'Cache-Control': 'no-cache',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      const data = await response.json();
      this.setState({ board: data.board });
      this.setState({ board_id: data.board.id });
      this.setState({ projects: data.projects });
      this.setState({ types: data.types });
      this.setState({ boards: data.boards });
      this.setState({ showLoader: false });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <div className="main-content">
        <div className="container-fluid pm_tool mt--7">
          <div className="row custom_height">
            <div className="col-12">
              <div className="card shadow">
                <div className="card-header pm_header">
                  <div className="col-6">
                    <ul className="list-inline float-start m-0">
                      <li><a href="#"><i className="fas fa-share-alt" aria-hidden="true"></i></a></li>
                      <li>
                        <input className="form-control border-0 fs-4" defaultValue={this.state.board.name} type="text" onBlur={(e) => this.changeBoardName(e, this.state.board_id)} />
                      </li>
                      <li><a href="#"><i className="fas fa-exclamation-circle" aria-hidden="true"></i></a></li>
                      <li><a href="#"><i className="fas fa-star" aria-hidden="true"></i></a></li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="list-inline float-end m-0">
                      <li className="d-none d-lg-inline-block">
                        <div className="btn-group">
                          <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-briefcase"></i> Boards</button>
                          <div className="dropdown-menu">
                            {this.state.boards.map((boardData) =>
                              <span className={(boardData.id == this.state.board_id) ? 'dropdown-item active' : 'dropdown-item'} key={boardData.id} onClick={() => this.changeActiveBoard(boardData.id)}>
                                <i className="fas fa-copy fa-fw mr-2"></i>
                                {boardData.name}
                              </span>
                            )
                            }
                            <span className="dropdown-item" onClick={() => this.addNewBoard()}>
                              <i className="fas fa-plus fa-fw mr-2"></i>
                              New Board
                            </span>
                          </div>
                        </div>
                      </li>
                      <li className="d-none d-lg-inline-block">
                        <div className="btn-group">
                          <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Board Power-Ups</button>
                          <div className="dropdown-menu">
                            <a className="dropdown-item" href="#"><i className="fas fa-copy fa-fw mr-2"></i>Board Views</a>
                            <a className="dropdown-item" href="#"><i className="fas fa-signal-alt fa-fw mr-2"></i>Dashboards</a>
                            <a className="dropdown-item" href="#"><i className="fas fa-robot fa-fw mr-2"></i>Automations</a>
                            <a className="dropdown-item" href="#"><i className="fas fa-plug fa-fw mr-2"></i>Integrations</a>
                            <a className="dropdown-item" href="#"><i className="fas fa-puzzle-piece fa-fw mr-2"></i>Apps Marketplace</a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-ellipsis-h"></i></a>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#"><i className="fas fa-copy fa-fw mr-2"></i>Board Views</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-signal-alt fa-fw mr-2"></i>Dashboards</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-robot fa-fw mr-2"></i>Automations</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-plug fa-fw mr-2"></i>Integrations</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-puzzle-piece fa-fw mr-2"></i>Apps Marketplace</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-body">
                  <div className="pm_subHeader mb-1 mb-md-4">
                    <ul className="list-inline m-0 list_styleOne">
                      <li className="dropdown active">
                        <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fas fa-home fa-fw mr-1"></i><span>Main Table</span><i className="fas fa-ellipsis-h ml-1"></i></a>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#"><i className="fas fa-star fa-fw mr-2"></i>Add to my favorites</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-pencil-alt fa-fw mr-2"></i>Rename</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-copy fa-fw mr-2"></i>Duplicate</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-share-alt fa-fw mr-2"></i>Share</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-home fa-fw mr-2"></i>Set as board default</a>
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" href="#"><i className="fas fa-trash fa-fw mr-2"></i>Delete</a>
                        </div>
                      </li>
                      <li className="d-none d-lg-inline-block dropdown">
                        <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><span>Gantt Table</span><i className="fas fa-ellipsis-h ml-1"></i></a>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#"><i className="fas fa-star fa-fw mr-2"></i>Add to my favorites</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-pencil-alt fa-fw mr-2"></i>Rename</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-copy fa-fw mr-2"></i>Duplicate</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-share-alt fa-fw mr-2"></i>Share</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-home fa-fw mr-2"></i>Set as board default</a>
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" href="#"><i className="fas fa-trash fa-fw mr-2"></i>Delete</a>
                        </div>
                      </li>
                      <li className="d-none d-lg-inline-block dropdown">
                        <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><span>Dashboard</span><i className="fas fa-ellipsis-h ml-1"></i></a>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#"><i className="fas fa-star fa-fw mr-2"></i>Add to my favorites</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-pencil-alt fa-fw mr-2"></i>Rename</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-copy fa-fw mr-2"></i>Duplicate</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-share-alt fa-fw mr-2"></i>Share</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-home fa-fw mr-2"></i>Set as board default</a>
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" href="#"><i className="fas fa-trash fa-fw mr-2"></i>Delete</a>
                        </div>
                      </li>
                      <li className="d-none d-lg-inline-block dropdown">
                        <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><span>Renewals</span><i className="fas fa-ellipsis-h ml-1"></i></a>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#"><i className="fas fa-star fa-fw mr-2"></i>Add to my favorites</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-pencil-alt fa-fw mr-2"></i>Rename</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-copy fa-fw mr-2"></i>Duplicate</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-share-alt fa-fw mr-2"></i>Share</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-home fa-fw mr-2"></i>Set as board default</a>
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" href="#"><i className="fas fa-trash fa-fw mr-2"></i>Delete</a>
                        </div>
                      </li>
                      <li className="d-none d-lg-inline-block dropdown">
                        <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><span>Gantt</span><i className="fas fa-ellipsis-h ml-1"></i></a>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#"><i className="fas fa-star fa-fw mr-2"></i>Add to my favorites</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-pencil-alt fa-fw mr-2"></i>Rename</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-copy fa-fw mr-2"></i>Duplicate</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-share-alt fa-fw mr-2"></i>Share</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-home fa-fw mr-2"></i>Set as board default</a>
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" href="#"><i className="fas fa-trash fa-fw mr-2"></i>Delete</a>
                        </div>
                      </li>
                      <li className="d-none d-lg-inline-block dropdown">
                        <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><span>Table</span><i className="fas fa-ellipsis-h ml-1"></i></a>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#"><i className="fas fa-star fa-fw mr-2"></i>Add to my favorites</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-pencil-alt fa-fw mr-2"></i>Rename</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-copy fa-fw mr-2"></i>Duplicate</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-share-alt fa-fw mr-2"></i>Share</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-home fa-fw mr-2"></i>Set as board default</a>
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" href="#"><i className="fas fa-trash fa-fw mr-2"></i>Delete</a>
                        </div>
                      </li>
                      <li className="d-inline-block d-lg-none dropdown show_dropdownIcon">
                        <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><span>More</span></a>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#"><i className="fas fa-pencil-alt fa-fw mr-2"></i>Gantt Table</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-copy fa-fw mr-2"></i>Dashboard</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-share-alt fa-fw mr-2"></i>Renewals</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-home fa-fw mr-2"></i>Gantt</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-trash fa-fw mr-2"></i>Table</a>
                        </div>
                      </li>

                      <li className="d-inline-block d-lg-none dropdown show_dropdownIcon">
                        <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fas fa-filter mr-1"></i><span>Filter</span></a>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#"><i className="fas fa-pencil-alt fa-fw mr-2"></i>New Project</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-share-alt fa-fw mr-2"></i>Person</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-home fa-fw mr-2"></i>Sort</a>
                          <a className="dropdown-item" href="#"><i className="fas fa-trash fa-fw mr-2"></i>Hide</a>
                        </div>
                      </li>

                      <li className="d-none d-lg-inline-block"><a href="#"><i className="fas fa-plus"></i></a></li>
                    </ul>
                    <ul className="list-inline m-0 list_styleOne d-none d-lg-inline-block">
                      <li><a href="#"><i className="fas fa-plug mr-1"></i>Integrate</a></li>
                      <li><a href="#"><i className="fas fa-robot mr-1"></i>Automate <span className="entity-counter">/ 31</span></a></li>
                    </ul>
                  </div>
                  <div className="pm_content">
                    <div className="inner_menuBar d-none d-lg-inline-block">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link with_dropDown pr-2 active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">New Project</a>
                          <button type="button" className="btn dropdown-toggle dropdown-toggle-spli mr-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="sr-only">Toggle Dropdown</span>
                          </button>
                          <div className="dropdown-menu">
                            <a className="dropdown-item" href="#"><i className="fas fa-star fa-fw mr-2"></i>Add to my favorites</a>
                            <a className="dropdown-item" href="#"><i className="fas fa-star fa-fw mr-2"></i>Rename</a>
                          </div>
                        </li>
                        <li className="nav-item">
                          <Search searchInit={this.searchProjects} />

                        </li>
                        <li className="nav-item">
                          <a className="nav-link" id="contact-tab" data-bs-toggle="tab" href="" role="tab" aria-controls="contact" aria-selected="false"><i className="fas fa-user-circle mr-1"></i>Person</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" id="contact-tab" data-bs-toggle="tab" href="" role="tab" aria-controls="contact" aria-selected="false"><i className="fas fa-filter mr-1"></i>Filter</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" id="contact-tab" data-bs-toggle="tab" href="" role="tab" aria-controls="contact" aria-selected="false"><i className="fas fa-sort mr-1"></i>Sort</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" id="contact-tab" data-bs-toggle="tab" href="" role="tab" aria-controls="contact" aria-selected="false"><i className="fas fa-eye-slash mr-1"></i>Hide</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" id="contact-tab" data-bs-toggle="tab" href="" role="tab" aria-controls="contact" aria-selected="false"><i className="fas fa-ellipsis-h"></i></a>
                        </li>
                      </ul>
                    </div>
                    <div className="project-loader d-inline-block float-end">
                      {this.state.showLoader === true &&
                        <i className="fa fa-spin fa-spinner"></i>
                      }
                    </div>
                  </div>
                  <div className="pm_content">
                    <div className="tab-content mt-1 mt-md-5" id="myTabContent">
                      <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="project_table table-responsive">
                          {this.state.board.groups &&
                            <SortableList items={this.state.board.fields} axis={'x'} onSortEnd={this.onSortEnd} types={this.state.types} />
                          }
                          {this.state.board.groups && this.state.board.groups.map((table, groupIndex) =>
                            <div key={table.id} className={'leads_table mb-4 table-' + table.id} >
                              <div className="no-toggle-icon dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <h4 style={{ color: table.color_scheme }} ><i className="fas fa-chevron-down mr-3"> </i> {table.name}</h4>
                              </div>
                              <div className="dropdown-menu dropdown-menu-start table-popup-box">
                                <div className="row col-12 p-1">
                                  <div className="form-group col-4">
                                    <input type="color" className="form-control form-control-color" defaultValue={table.color_scheme} onBlur={(e) => this.updateGroupColor(e, this.state.board_id, table.id)} />
                                  </div>
                                  <div className="form-group col-8">
                                    <input type="text" className="form-control" defaultValue={table.name} onBlur={(e) => this.updateGroupName(e, this.state.board_id, table.id)} />
                                  </div>
                                </div>
                              </div>

                              <table className="table table-bordered mb-4">
                                <thead>
                                  <tr>
                                    <th style={{ borderLeft: '5px solid ' + table.color_scheme }}>
                                      <div className='table-checkbox'>
                                        <span><input type="checkbox" className="projectTable_checkbox check-all" value="all" /></span>
                                      </div>
                                    </th>

                                    {this.state.board.fields.map((heading) =>
                                      <th key={heading.id}>
                                        <div className='table-contentbox'>

                                          {heading.is_fixed === 1 &&
                                            <input className="form-control border-0" type="text" key={heading.field_name} defaultValue={heading.field_name} onBlur={(e) => this.updateFieldName(e, this.state.board_id, heading.id)} />
                                          }
                                          {heading.is_fixed === 0 &&
                                            <div className="col-auto">
                                              <div className="no-toggle-icon dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="ui-icon ui-icon-arrowthick-2-n-s"></span>
                                                <span>{heading.field_name}</span>
                                              </div>
                                              <div className="dropdown-menu table-popup-box dropdown-menu-end">
                                                <div className="px-3 row">
                                                  <div className="col-10">
                                                    <input className="form-control float-start" type="text" key={heading.field_name} defaultValue={heading.field_name} onBlur={(e) => this.updateFieldName(e, this.state.board_id, heading.id)} />
                                                  </div>
                                                  <div className="col-2">

                                                    <button className="float-end btn btn-sm btn-outline-danger" title="Remove" onClick={() => this.deleteBoardField(this.state.board_id, heading.id)}><i className="fa fa-trash"></i></button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      </th>
                                    )}
                                    <th>
                                      <div className='table-addfieldbox'>
                                        <div className="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <i className="fas fa-plus"></i>
                                        </div>
                                        <div className="dropdown-menu dropdown-menu-end table-popup-box p-2">
                                          <div className="row">
                                            {this.state.types.map((field) =>
                                              <div className="col-6 mb-2" key={field.id}>
                                                <span className="btn btn-outline-dark" onClick={() => this.addNewHeader(this.state.board_id, field.type, field.name)}>{field.name}</span>
                                              </div>
                                            )
                                            }
                                          </div>
                                        </div>
                                      </div>

                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {table.projects && table.projects.map((project) =>
                                    <tr key={project.id}>
                                      <td style={{ borderLeft: '5px solid ' + table.color_scheme }}>
                                        <div className='table-checkbox'>
                                          <span><input type="checkbox" className="projectTable_checkbox" value={project.id} /></span>
                                        </div>
                                      </td>
                                      {this.state.board.fields.map((heading) =>
                                        <td key={heading.id} data-id={heading.id}>
                                          <div className='table-contentbox'>
                                            <TableCell content={this.getFieldHtml(this.state.board_id, project.id, heading.id)} />
                                          </div>
                                        </td>
                                      )}
                                      <td>
                                        <div className='table-addfieldbox'>&nbsp;</div>
                                      </td>
                                    </tr>
                                  )
                                  }
                                  <tr>
                                    <td style={{ borderLeft: '5px solid ' + table.color_scheme }}>
                                      <div className='table-checkbox'>
                                        <span></span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className='table-contentbox'>
                                        <span><input type="text" className="form-control" placeholder="+ Add Project" defaultValue={''} onBlur={(event) => this.addProject(event, this.state.board_id, table.id)} /></span>
                                      </div>
                                    </td>
                                    <td colSpan="100%">
                                      {groupIndex == 0 && this.state.board.fields.map((heading) =>
                                        <div key={heading.id} data-id={heading.id}>
                                          <div className='table-contentbox'>
                                            <TableCell content={this.modelHtml(this.state.board_id, heading)} />
                                          </div>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )
                          }
                        </div>
                        <div className="col-12 pt-3">
                          <button className="btn btn-sm btn-outline-dark" onClick={() => { this.addNewGroup(this.state.board_id) }}>
                            <i className="fa fa-plus"></i> Add New Group
                          </button>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                      </div>
                      <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab"></div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="modal" id="field-update-modal" tabIndex="-1">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Update Field</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body" dangerouslySetInnerHTML={{ __html: this.state.editFieldHtml }}>
                  </div>
                </div>
              </div>
            </div>
            {this.state.board_id == '' &&
              <div className="modal show" id="select-board-modal" tabIndex="-1">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Update Field</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      testing
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Main