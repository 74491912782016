import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

const SortableItem = (props) => {
  return <th className='text-center' >
    <div className='table-contentbox'>
        {props.value.field_name}
      </div></th>;
};

export default SortableElement(SortableItem);